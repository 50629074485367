"use client";
import React, { useCallback, useState } from "react";
import { addToCartWish } from "@/context/cart/api";
import Loader from "../loading-spinner";
import { useRouter } from "next/navigation";
import { useCart } from "@/context/cart/CartContext";
import { useAuth } from "../../context/AuthContext";
import getToken from "@/hook/getToken";
import { cn } from "@/utils/cn";
const BuyNow = ({ productId, availableForSale, orderQty, className }) => {
  const router = useRouter();
  const token = getToken();
  const apiUrl = token ? '/v1/cart/add' : '/v1/cart/add-quick-buy';
  const { isBuyNow, setIsBuyNow, setCart, setBuyItems } = useCart();
  const { setIsSignUpOpen, setIsSignInOpen } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const handleBuyNow = async (id) => {
    const body = {
      product_id: id,
      order_qty: orderQty,
      buy_now: true,
    };
    const res = await addToCartWish(apiUrl, body).then((res) => res.json());
    if (res.success) {
      const sessionId = res?.session_id;
      if (sessionId) {
        localStorage.setItem('sessionId', sessionId);
      } else {
        localStorage.removeItem('sessionId');
      }

      if (!token && res) {
        setBuyItems(res);
      }
      // const { max_retail_price, list_price, discount } = response;
      // const encodedMaxRetailPrice = encodeURIComponent(max_retail_price);
      // const encodedListPrice = encodeURIComponent(list_price);
      // const encodedDiscount = encodeURIComponent(discount);
      setIsLoading(false);
      if (token) {
        setIsBuyNow((pre) => !pre);
      }
      window.location.href = `/checkout?buynow=true`;
    }
  };

  return (
    <button
      onClick={() => handleBuyNow(productId)}
      type="button"
      className={cn(
        "w-full border border-solid border-white text-sm font-sans flex-1 flex justify-center items-center",
        availableForSale ? "bg-black text-white font-semibold hover:scale-105" : "bg-red-400 text-white pointer-events-none",
        "hover:bg-black hover:text-white py-2 rounded-full cursor-pointer transition-all duration-300 transform",
        className
      )}
      disabled={!availableForSale}
    >
      Buy Now{isLoading ? <Loader className="w-4 h-4" /> : null}
    </button>
  );
};

export default BuyNow;
